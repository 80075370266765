import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileFacade } from '../facades/profile.facade';
import { AuthService } from '../services/auth/auth.service';
import {Subscription} from "rxjs";

@Component({
    template: ``,
    standalone: true,
})

export class LoginCallbackComponent implements  OnInit,OnDestroy{
    subscriptions: Subscription;
    constructor(private _profileFacade: ProfileFacade, private _auth: AuthService, private _route: Router) {}
    ngOnInit(): void {
       this.subscriptions = this._auth.tokenReceived.subscribe(() => {
            this._profileFacade.isFinishingRegistrationRequired$.subscribe(goToSignUp => {
                const isLoggedIn = this._auth.isTokenValid();
                if(isLoggedIn && goToSignUp){
                    this._route.navigate(["/signUp"])
                } else {
                    this._route.navigate(["/"])
                }
            })
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
