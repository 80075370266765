import {createReducer, on} from '@ngrx/store';
import {
  loadCurrentTrainingProgram,
  loadCurrentTrainingProgramFailure,
  loadCurrentTrainingProgramSuccess,
  loadRecommendedTrainingPrograms,
  loadRecommendedTrainingProgramsFailure,
  loadRecommendedTrainingProgramsSuccess,
  startTrainingProgramFailure,
  startTrainingProgramIsLoading,
  startTrainingProgramSuccess,
} from '../actions/training-programs.actions';
import {ActiveTrainingProgramStageProgression} from '../../../models/training-program-progression';
import {StrapiTrainingProgram} from "../../../models/strapi-training-program";
import {DataFetchState} from "../../../models/fetch-state";
import {Guid} from "../../../models/types";

export interface TrainingProgramsState {
  recommended: DataFetchState<Array<StrapiTrainingProgram>>;
  currentTrainingProgram: DataFetchState<ActiveTrainingProgramStageProgression>;
  isTrainingProgramStarting: boolean;
  startingTrainingProgramId?: Guid | null;
}

export const initialState: TrainingProgramsState = {
  recommended: {
    data: undefined,
    isLoading: false,
    error: null
  },
  currentTrainingProgram: {
    data: undefined,
    isLoading: false,
    error: null
  },
  isTrainingProgramStarting: false,
  startingTrainingProgramId: null
};

export const trainingProgramsReducer = createReducer<TrainingProgramsState>(initialState,
  //Load recommended
  on(loadRecommendedTrainingPrograms, (state) => ({
    ...state,
    recommended: {
      ...state.recommended,
      isLoading: true
    }
  })),
  on(
    loadRecommendedTrainingProgramsSuccess,
    (state, {trainingPrograms}) => ({
      ...state,
      recommended: {
        data: trainingPrograms,
        isLoading: false,
        error: null
      },
    })
  ),
  on(loadRecommendedTrainingProgramsFailure, (state, {error}) => ({
    ...state,
    recommended: {
      data: undefined,
      isLoading: false,
      error: error,
    },
  })),
  //Start program
  on(startTrainingProgramIsLoading, (state, {trainingProgramId}) => ({
    ...state,
    isTrainingProgramStarting: true,
    startingTrainingProgramId: trainingProgramId,
  })),
  on(startTrainingProgramFailure, (state) => ({
    ...state,
    isTrainingProgramStarting: false,
    startingTrainingProgramId: null,
  })),
  on(startTrainingProgramSuccess, (state, {progression}) => ({
    ...state,
    currentTrainingProgram: {
      data: progression,
      isLoading: false,
      error: null
    }
  })),
  //User challenges
  on(loadCurrentTrainingProgram, (state) => ({
    ...state,
    currentTrainingProgram: {
      ...state.currentTrainingProgram,
      isLoading: true
    }
  })),
  on(loadCurrentTrainingProgramSuccess, (state, {program}) => ({
    ...state,
    currentTrainingProgram: {
      data: program,
      isLoading: false,
      error: null
    }
  })),
  on(loadCurrentTrainingProgramFailure, (state, {error}) => {
    return ({
      ...state,
      currentTrainingProgram: {
        data: undefined,
        isLoading: false,
        error: error
      }
    })
  })
);
