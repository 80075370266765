import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {AppState} from 'src/app/common/store';
import {
  bookmarkProgressionStep,
  completeChallenge,
  loadCurrentChallenges,
  loadCurrentPlaylist, loadDaysActive, loadDaysActiveSuccess,
  startChallenge,
} from '../common/store/actions/challenges.actions';
import {Challenge, ChallengeCompleteResult, ChallengeProgression,} from '../models/challenge';
import {Playlist} from "../models/playlist";
import {Guid} from "../models/types";
import {DataFetchState} from "../models/fetch-state";

@Injectable({
  providedIn: 'root',
})
export class ChallengesFacade implements OnDestroy {
  private subscriptions: Subscription;
  private _currentPlaylist$: Observable<DataFetchState<Playlist>>;
  private _currentChallenges$: Observable<DataFetchState<Challenge[]>>;
  private _daysActive$: Observable<DataFetchState<number>>;

  acceptingChallengeId$: Observable<number | null>;

  constructor(private _store: Store<AppState>) {
    this._currentPlaylist$ = this._store.select((x) => x.challenges.playlist)
    this._currentChallenges$ = this._store.select((x) => x.challenges.challenges);
    this._daysActive$ = this._store.select((x) => x.challenges.daysActive);
  }


  get currentPlaylist$() {
    this._store.dispatch(loadCurrentPlaylist({reload: false}));
    console.log("Fetching the playlist")
    return this._currentPlaylist$;
  }

  get currentChallenges$() {
    this._store.dispatch(loadCurrentChallenges({reload: false}));
    return this._currentChallenges$;
  }

  get daysActive$() {
    this._store.dispatch(loadDaysActive({reload: false}));
    return this._daysActive$;
  }


  acceptChallenge = (challengeId: Guid) => {
    this._store.dispatch(startChallenge({challengeId}));
  };

  validateChallengeCompletion = (challenge: Challenge, result: ChallengeCompleteResult): boolean => {
    console.debug("Validating challenge completion", challenge, result);
    if (challenge.response == null || challenge.response.type === "no-response") {
      return true;
    }
    switch (challenge.response.type) {
      case "range":
        return result.numberValue !== undefined;
      case "long-text":
        return result.stringValue !== undefined;
      case "short-text":
        return result.stringValue !== undefined;
      case "yes-no":
        return result.boolValue !== undefined;
    }
    return false;
  };

  completeChallenge = (result: ChallengeCompleteResult) => {
    this._store.dispatch(completeChallenge({result}));
  };

  /** @deprecated - remove after or if Andreas/Janniche gives OK */
  bookmarkChallengeStep = (
    progress: number,
    progression: ChallengeProgression
  ) => {
    if (progress > progression.progress)
      this._store.dispatch(bookmarkProgressionStep({progress, progression}));
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
