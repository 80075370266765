import * as Highcharts from 'highcharts';
import { Component, OnInit } from '@angular/core';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import HC_exporting from 'highcharts/modules/exporting';
import HC_more from 'highcharts/highcharts-more';
import { StatisticsFacade } from 'src/app/facades/statistics.facade';
import { PersonaTypeScore } from 'src/app/models/statistics';
import { Observable } from 'rxjs';
import { BarChartComponent } from '../../components/bar-chart/bar-chart.component';
import { DcCardContentComponent } from '../../extended-components/dc-card/ui/dc-card-content.component';
import { DcCardHeaderComponent } from '../../extended-components/dc-card/ui/dc-card-header.component';
import { DcCardComponent } from '../../extended-components/dc-card/dc-card.component';
import { ProgressIndicatorComponent } from '../../components/progress-indicator/progress-indicator.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { DataFetchState } from "../../models/fetch-state";
import { ActiveTrainingProgramStageProgression } from "../../models/training-program-progression";
import { TrainingProgramFacade } from "../../facades/training-program.facade";
import { ButtonDirective } from "../../directives/button.directive";
import { Router } from "@angular/router";
import { ProfileFacade } from "../../facades/profile.facade";
import { Profile } from "../../models/profile";
import { PersonalBirdComponent } from "../../components/personal-bird/personal-bird.component";
import {PersonaModalComponent} from "../../components/PersonaModalComponent/persona-modal-component";
import {MatDialog} from "@angular/material/dialog";

HC_exporting(Highcharts);
HC_more(Highcharts);
HCSoldGauge(Highcharts);

const personaTypeNames = [
  'Skyldneren',
  'Impulskontrolløren',
  'Ødeleggeren',
  'Formeren',
  'Arbeidsjern',
  'Undergraveren',
  'Perfeksjonisten'
] as const;

const personaTypes = [
  'guilt tripper',
  'inner controller',
  'destroyer',
  'molder',
  'taskmaster',
  'underminer',
  'perfectionist'
] as const;

type personaType = (typeof personaTypes)[number];

const personaTypeMap: Record<personaType, string> = {
  'guilt tripper': 'Skyldneren',
  'inner controller': 'Impulskontrolløren',
  'destroyer': 'Ødeleggeren',
  'taskmaster': 'Arbeidsjern',
  'underminer': 'Undergraveren',
  'perfectionist': 'Perfeksjonisten',
  'molder': 'Formeren',
}

interface PersonaContent {
  title: string;
  description: string;
}


@Component({
  selector: 'dc-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProgressIndicatorComponent,
    DcCardComponent,
    DcCardHeaderComponent,
    DcCardContentComponent,
    BarChartComponent,
    AsyncPipe,
    HighchartsChartModule,
    ButtonDirective,
    PersonalBirdComponent,
  ],
})
export class PulseComponent implements OnInit {
  personaTypes$: Observable<DataFetchState<PersonaTypeScore[]>>;
  profile$: Observable<DataFetchState<Profile>>;
  currentTrainingProgram$: Observable<DataFetchState<ActiveTrainingProgramStageProgression>>;

  constructor(
    private _router: Router,
    private _trainingProgramFacade: TrainingProgramFacade,
    private statisticsFacade: StatisticsFacade,
    private _profileFacade: ProfileFacade,
    private dialog: MatDialog  // Inject MatDialog
  ) {}

  public Highcharts: any = Highcharts; // required
  public chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  public chartOptions: Observable<any>;
  private personaTypeLookup: { [key: string]: PersonaContent } = {
    'guilt tripper': {
      title: 'Skyldbæreren',
      description: 'Du lever med en konstant følelse av skyld, og klarer ikke å tilgi deg selv for tidligere feil. Dårlig samvittighet plager deg stadig, og en byrde du bærer med deg konstant.'
    },
    'inner controller': {
      title: 'Impulskontrolløren',
      description: 'Du sliter med vanene dine og føler ofte skam over at du ikke klarer å kontrollere impulsene dine. Det er en konstant kamp mellom hva du forventer av deg selv og følelsen av å mislykkes.'
    },
    'destroyer': {
      title: 'Ødeleggeren',
      description: 'Du angriper deg selv så voldsomt at du tviler på din egen verdi og eksistens. Alt føles feil, og du klarer sjelden å se noe positivt i deg selv eller dine prestasjoner.'
    },
    'molder': {
      title: 'Konformisten',
      description: 'Du prøver hardt å passe inn og leve opp til andres forventninger, enten det er sosialt eller i forhold til familie og kultur. Men du føler ofte skam når du ikke klarer å nå opp til disse standardene.'
    },
    'taskmaster': {
      title: 'Flinkisen',
      description: 'For deg handler suksess om å fullføre oppgavene dine, og du er mer opptatt av å få ting gjort enn å oppnå perfekt kvalitet. Du er pragmatisk og sørger for at resultatet er godt nok til å komme videre.'
    },
    'underminer': {
      title: 'Sabotøren',
      description: 'Du undergraver både deg selv og andre, ofte på grunn av lav selvtillit. Du føler at du ikke kan få til noe, og derfor sprer du negativitet som en slags forsvarsmekanisme.'
    },
    'perfectionist': {
      title: 'Perfeksjonisten',
      description: 'Du streber alltid etter å være best og har svært høye standarder for både deg selv og andre. Kvalitet er alt for deg. Du sørger for at alt du gjør er så nært perfekt som mulig.'
    },
  }

  ngOnInit(): void {
    this.currentTrainingProgram$ = this._trainingProgramFacade.currentTrainingProgramProgression$;
    this.profile$ = this._profileFacade.profile$;
    this.statisticsFacade.loadPersonaTypes();
    this.personaTypes$ = this.statisticsFacade.personaTypes$;

    this.chartOptions = new Observable<any>(obs => {
      this.personaTypes$.subscribe(personaTypes => {
        if (personaTypes) {
          const options = this.progressTracking;
          if (personaTypes.data) {
            options.series = [
              {
                name: 'Nå',
                data: personaTypes.data.map(personaType => personaType.currentScore), // Make sure we select the right persona type according to the order in the personaTypeNames array
                pointPlacement: 'on',
                type: "area",
              }
            ];
            obs.next(options);
          }
        }
      });
      return obs;
    });
  }

  goToProfile() {
    this._router.navigate(['/profile']);
  }

  chartCallback = (chart: Highcharts.Chart): void => {
    setTimeout(() => {
      chart.reflow();
    }, 0);
    const xAxis = chart.xAxis[0];
    if (xAxis && xAxis.ticks) {
      Object.keys(xAxis.ticks).forEach(key => {
        const tick = xAxis.ticks[key];
        if (tick?.label) {
          tick.label.on('click', () => {
            const personaType = personaTypes[Number(key)];
            const personaContent = this.personaTypeLookup[personaType];
            if (personaContent) {
              this.dialog.open(PersonaModalComponent, {
                width: '70%',
                data: personaContent,
              });
            }
          }).css({
            cursor: 'pointer',
            textDecoration: 'underline',
            color: '#12525A'
          });
        }
      });
    }
  }

  private progressTracking: any = {
    chart: {
      polar: true,
      type: 'line',
    },
    title: {
      text: null,
      style: {
        color: '#f8f8f8'
      }
    },
    pane: {
      size: '50%',
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      categories: personaTypeNames,
      gridLineColor: 'transparent',
      tickmarkPlacement: 'on',
      linewidth: 0,
      labels: {
        style: {
          color: '#12525A',
          fontSize: '12px'
        }
      }
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
        color: 'hsl(44, 90%, 52%)',
      }
    },
    yAxis: {
      tickAmount: 5,
      gridLineInterpolation: 'polygon',
      gridLineColor: '#12525A',
      lineWidth: 0,
      min: 0,
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: false,
      pointFormat: '<span style="color:{series.color}">{series.name}: <b>${point.y:,.0f}</b><br/>',
    },
    legend: {
      enabled: false,
      align: 'top',
      verticalAlign: 'top',
      layout: 'vertical',
      itemStyle: {
        // color: '#f8f8f8'
      },
    }
  };
}
