import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatButton} from "@angular/material/button";
import {ButtonDirective} from "../../directives/button.directive";

interface PersonaData {
  title: string;
  description: string;
}

@Component({
  selector: 'dc-persona-modal',
  templateUrl: './persona-modal.component.html',
  styleUrl: './persona-modal.component.scss',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    MatButton,
    ButtonDirective
  ],
  standalone: true
})
export class PersonaModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PersonaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonaData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
