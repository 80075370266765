import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, delay, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import * as challengesActions from '../common/store/actions/challenges.actions';
import {ChallengesService} from '../services/challenges/challenges.service';
import * as questionnairesActions from '../common/store/actions/questionnaires.actions';
import * as trainingProgramActions from '../common/store/actions/training-programs.actions';
import {HttpErrorResponse} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppState} from "../common/store";
import {
  loadCurrentChallenges,
  loadCurrentChallengesIsLoading, loadDaysActive,
  loadDaysActiveIsLoading
} from "../common/store/actions/challenges.actions";

@Injectable()
export class ChallengesEffects {
  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private challengesService: ChallengesService
  ) {
  }

  reloadChallengesProgressions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        challengesActions.completeChallengeSuccess,
        trainingProgramActions.startTrainingProgramSuccess,
        questionnairesActions.submitQuestionnaireSuccess
      ),
      mergeMap(() => of(loadCurrentChallenges({reload: true}))))
  );

  tryLoadChallengesProgressions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengesActions.loadCurrentChallenges),
      withLatestFrom(this.store$.select(state => state.challenges.challenges)),
      filter(([{reload}, {data, isLoading}]) => {
        return reload || (!data && !isLoading);
      }),
      mergeMap(() => of(loadCurrentChallengesIsLoading())))
  );

  loadDaysActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDaysActive, loadDaysActiveIsLoading),
      mergeMap((value, index) => {
        return this.challengesService.getDaysActive().pipe(
          map((daysActive) => {
            return challengesActions.loadDaysActiveSuccess({ daysActive });
          }),
          catchError((error: HttpErrorResponse) => {
            console.error("[challenges.effects]:loadCurrentChallenge error", error);
            return of(challengesActions.loadCurrentChallengesFailure({error:error}));
          })
        )
      }),
    )
  );

  loadChallengesProgressions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentChallengesIsLoading),
      mergeMap((value, index) => {
          return this.challengesService.getChallengeProgressions().pipe(
            map((challenges) => {
              return challengesActions.loadCurrentChallengesSuccess({challenges});
            }),
            catchError((error: HttpErrorResponse) => {
              console.error("[challenges.effects]:loadCurrentChallenge error", error);
              return of(challengesActions.loadCurrentChallengesFailure({error:error}));
            })
          )
        }
      )
    )
  );

  startChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengesActions.startChallenge),
      mergeMap(({challengeId}) =>
        this.challengesService.startChallenge(challengeId).pipe(
          map((challenge) => {
            return challengesActions.startChallengeSuccess({challenge});
          }),
          catchError((error: HttpErrorResponse) => {
            console.error(error);
            return of(challengesActions.loadCurrentChallengesFailure({error:error}));
          })
        )
      )
    )
  );

  completeChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengesActions.completeChallenge),
      mergeMap(({result}) =>
        this.challengesService.completeChallenge(result).pipe(
          map(() => challengesActions.completeChallengeSuccess()),
          catchError((error: HttpErrorResponse) => {
            console.error(error);
            return throwError(() => error);
          }
        )
      )))
  );

  tryLoadCurrentPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengesActions.loadCurrentPlaylist),
      withLatestFrom(this.store$.pipe(map(state => state.challenges.playlist))),
      filter(([{reload}, {data, isLoading}]) =>
          reload || (!data && !isLoading)
      ),
      mergeMap(() => of(challengesActions.loadCurrentPlaylistIsLoading())),
    )
  );


  loadCurrentPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengesActions.loadCurrentPlaylistIsLoading),
      mergeMap(() =>
        this.challengesService.getCurrentPlaylist().pipe(
          map((playlist) => {
            return challengesActions.loadCurrentPlaylistSuccess({playlist});
          }),
          catchError((error: HttpErrorResponse) => {
            console.error("[challenges.reducers] LoadPlaylistError: ", error);
            return of(challengesActions.loadCurrentPlaylistFailure({error:error}));
          })
        )
      )
    )
  );
}
