import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TextChallengeComponent} from "../text-challenge/text-challenge.component";
import {NgIf} from "@angular/common";
import {Challenge, ChallengeCompleteResult, ChallengeProgressionState} from "../../../models/challenge";
import {FullScreenOverlayComponent} from "../../full-screen-overlay/full-screen-overlay.component";
import {ChallengesFacade} from "../../../facades/challenges.facade";
import {SimpleModalComponent} from "../../simple-modal/simple-modal.component";
import {ButtonDirective} from "../../../directives/button.directive";
import {AudioPlayerComponent} from "../../audio-player/audio-player.component";
import {FeedbackComponent} from "../../feedback/feedback.component";

@Component({
  selector: 'dc-challenge',
  templateUrl: './challenge.component.html',
  styleUrl: './challenge.component.scss',
  standalone: true,
  imports: [
    TextChallengeComponent,
    NgIf,
    FullScreenOverlayComponent,
    SimpleModalComponent,
    ButtonDirective,
    AudioPlayerComponent,
    FeedbackComponent
  ],
})
export class ChallengeComponent implements OnInit {
  @Input() challenge: Challenge;
  @Output() private closeChallenge: EventEmitter<void> = new EventEmitter<void>();

  private challengeProgressionState: ChallengeProgressionState;
  showCancelModal: boolean = false;
  showFeedbackModal: boolean = false;

  constructor(private _challengesFacade: ChallengesFacade) {}

  ngOnInit() {
    this.challengeProgressionState = this.challenge.state;
  }

  onCloseChallenge() {
    //TODO: If the challenge is not completed, the challenge window should be closed without the challenge being completed
    if(this.challengeProgressionState === ChallengeProgressionState.InProgress) {
      //If the content has started playing, show a modal to confirm closing the challenge
      this.showCancelModal = true;
    } else {
      this._closeChallenge();
    }
  }

  _closeChallenge() {
    this.closeChallenge.emit();
  }

  onChallengeContentStarted() {
    this.challengeProgressionState = ChallengeProgressionState.InProgress;
  }

  onChallengeCompleted() {
    if(!!this.challenge.response) {
      this.showFeedbackModal = true;
    } else {
      this._completeChallenge();
    }
  }

  onCloseFeedback(feedback?: any) {
    console.debug("This should be triggering", feedback);
    this._completeChallenge(feedback);
  }

  private _completeChallenge(feedback?: any) {
    const challengeCompleteResult = {
      challengeId: this.challenge.id,
      state: ChallengeProgressionState.Completed,
    } as ChallengeCompleteResult;
    switch (typeof feedback) {
      case 'string':
        challengeCompleteResult.stringValue = feedback;
        break;
      case 'number':
        challengeCompleteResult.numberValue = feedback;
        break;
      case 'boolean':
        challengeCompleteResult.boolValue = feedback;
        break;
    }
    this._challengesFacade.completeChallenge(challengeCompleteResult);
    this._closeChallenge();
  }
}
