import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {AppState} from 'src/app/common/store';
import {
  loadRecommendedTrainingPrograms,
  startTrainingProgram,
} from '../common/store/actions/training-programs.actions';
import {ActiveTrainingProgramStageProgression} from "../models/training-program-progression";
import {StrapiTrainingProgram} from "../models/strapi-training-program";
import {DataFetchState} from "../models/fetch-state";
import {Guid} from "../models/types";

@Injectable({
  providedIn: 'root',
})
export class TrainingProgramFacade implements OnDestroy {
  private _currentTrainingProgramProgression$: Observable<DataFetchState<ActiveTrainingProgramStageProgression>>;
  private _recommendedTrainingPrograms$: Observable<DataFetchState<Array<StrapiTrainingProgram>>>;

  startingTrainingProgramId$: Observable<Guid | undefined | null>;
  private subscriptions: Subscription;

  constructor(private _store: Store<AppState>) {
    this._currentTrainingProgramProgression$ =
      this._store.select((x) => x.trainingPrograms.currentTrainingProgram);
    this._recommendedTrainingPrograms$ =
      this._store.select((x) => x.trainingPrograms.recommended);
    this.startingTrainingProgramId$ =
      this._store.select(({trainingPrograms}) => trainingPrograms.startingTrainingProgramId);
  }

  get currentTrainingProgramProgression$() {
    //this._store.dispatch(loadCurrentTrainingProgram({reload: false}));
    //The dispatch event above is triggered by the user logging in, so no need to explicitly call it here
    return this._currentTrainingProgramProgression$;
  }


  get recommendedTrainingPrograms$() {
    this._store.dispatch(loadRecommendedTrainingPrograms());
    return this._recommendedTrainingPrograms$;
  }

  startTrainingProgram(trainingProgramId: Guid) {
    console.log("[TrainingProgramFacade]: Dispatching startTrainingProgram action", trainingProgramId);
    this._store.dispatch(startTrainingProgram({trainingProgramId}));
  }

  startRecommendedTrainingProgram() {
    console.debug("[TrainingProgramFacade]: Starting recommended training program")
    this._store.dispatch(startTrainingProgram({}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
