import {createReducer, on} from '@ngrx/store';
import {Challenge,} from 'src/app/models/challenge';
import {
  completeChallenge,
  completeChallengeSuccess,
  loadCurrentChallengesFailure,
  loadCurrentChallengesIsLoading,
  loadCurrentChallengesSuccess,
  loadCurrentPlaylistFailure,
  loadCurrentPlaylistIsLoading,
  loadCurrentPlaylistSuccess, loadDaysActive, loadDaysActiveFailure, loadDaysActiveIsLoading, loadDaysActiveSuccess,
} from '../actions/challenges.actions';
import {Playlist} from "../../../models/playlist";
import {Guid} from "../../../models/types";
import {DataFetchState} from "../../../models/fetch-state";

export interface ChallengesState {
  challenges: DataFetchState<Challenge[]>;
  playlist: DataFetchState<Playlist>;
  acceptingChallenge: Guid | null;
  daysActive: DataFetchState<number>;
}

export const initialState: ChallengesState = {
  challenges: {
    data: undefined,
    isLoading: false,
    error: null,
  },
  acceptingChallenge: null,
  playlist: {
    data: undefined,
    isLoading: false,
    error: null,
  },
  daysActive: {
    data: 0,
    isLoading: false,
    error: null
  },
};

export const challengesReducer = createReducer(
  initialState,
  //Load recommended
  on(loadCurrentChallengesIsLoading, (state) => {
    return ({
      ...state,
      challenges: {
        ...state.challenges,
        isLoading: true,
      },
    })
  }),
  on(loadCurrentChallengesSuccess, (state, {challenges}) => {
    return {
      ...state,
      challenges: {
        data: challenges,
        isLoading: false,
        error: null,
      }
    };
  }),
  on(loadCurrentChallengesFailure, (state, action) => ({
    ...state,
    challenges: {
      ...state.challenges,
      isLoading: false,
      error: action.error,
    },
  })),
  on(completeChallenge, (state) => ({
    ...state,
    challenges: {
      ...state.challenges,
      isLoading: false //If we want to use this to show a spinner we should either select the challenge or add a new state
    },
  })),
  on(completeChallengeSuccess, (state) => ({
    ...state,
    challenges: {
      ...state.challenges,
      isLoading: false
    }
  })),


  on(loadDaysActive, (state) => {
    return ({
      ...state,
      daysActive: {
        ...state.daysActive,
        isLoading: false,
      },
    })
  }),
  on(loadDaysActiveIsLoading, (state) => {
    return ({
      ... state,
      daysActive: {
        data: null,
        isLoading: true,
        error: null
      }
    })
    }
  ),
  on(loadDaysActiveSuccess, (state, { daysActive }) =>  {
    return {
      ...state,
      daysActive: {
        data: daysActive,
        isLoading: false,
        error: null,
      }
    }
  }),
  on(loadDaysActiveFailure, (state, action) =>  {
    return {
      ...state,
      daysActive: {
        data: 0,
        isLoading: false,
        error: action.error,
      }
    }
  }),


  on(loadCurrentPlaylistSuccess, (state, {playlist}) => {
    return {
      ...state,
      playlist: {
        data: playlist,
        isLoading: false,
        error: null
      },
    };
  }),
  on(loadCurrentPlaylistIsLoading, (state) => {
    return ({
      ...state,
      playlist: {
        ...state.playlist,
        isLoading: true
      }
    })
  }),
  on(loadCurrentPlaylistFailure, (state, action) => {
    return ({
      ...state,
      playlist: {
        data: null,
        isLoading: false,
        error: action.error
      }
    })
  })
);
